/* global bowser, module, iurioData */
var iurio = iurio || {}; //eslint-disable-line

iurio.utils = {
	init: function () {
		if (!this.filenameEquals('a', 'A')) {
			this.filenameEquals = this.filenameEqualsFallback;
		}
		if (typeof bowser !== 'undefined') {
			iurio.utils.browser = bowser.getParser(window.navigator.userAgent);
		}
	},

	hookConsole: function () {
		if (document.documentMode || /Edge/.test(navigator.userAgent)) {
			return;
		}
		const origError = console.error;
		console.error = function (...args) {
			args.push(Error().stack);
			let res = '';
			let sep = '';
			for (let arg of args) {
				res += sep + JSON.stringify(arg);
				sep = ' ';
			}
			window.localforage.setItem('Err' + Date.now(), res);
			origError(...args);
		};
		const origWarn = console.warn;
		console.warn = function (...args) {
			args.push(Error().stack);
			let res = '';
			let sep = '';
			for (let arg of args) {
				res += sep + JSON.stringify(arg);
				sep = ' ';
			}
			window.localforage.setItem('Wrn' + Date.now(), res);
			origWarn(...args);
		};
	},

	cleanupStorage: async function () {
		const cleanupDate = new Date();
		cleanupDate.setDate(cleanupDate.getDate() - 7);
		const keysToDelete = [];
		const length = await window.localforage.length();
		for (let i = 0; i < length; ++i) {
			const key = await window.localforage.key(i);
			if (!key.startsWith('Err') && !key.startsWith('Wrn')) {
				continue;
			}
			const date = new Date(parseInt(key.substring(3)));
			if (date < cleanupDate) {
				keysToDelete.push(key);
			}
		}
		for (let key of keysToDelete) {
			await window.localforage.removeItem(key);
		}
	},

	isValidFileName: function (fileName) {
		if (fileName.length === 0 || fileName.length > 255) {
			return false;
		}
		return !(/[\x00-\x1F\x7F]/.test(fileName));
	},

	isValidFolderName: function (folderName) {
		return this.isValidFileName(folderName);
	},

	isValidEmailAddress: function (value) {
		if (!value || typeof value !== 'string') {
			return false;
		}
		return (function () {
			function _isByteLength(str, min, max) {
				var len = encodeURI(str).split(/%..|./).length - 1;
				return len >= min && (typeof max === 'undefined' || len <= max);
			}
			var emailUserUtf8Part = /^[a-z\d!#\$%&'\*\+\-\/=\?\^_`{\|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+$/i;
			var quotedEmailUserUtf8 = /^([\s\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|(\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*$/i;
			function _isFQDN(str) {
				var options = {
					requireTLD: !0,
					allowUnderscores: !1,
					allowTrailingDot: !1,
				};
				if (options.allowTrailingDot && str[str.length - 1] === '.') {
					str = str.substring(0, str.length - 1);
				}
				var parts = str.split('.');
				if (options.requireTLD) {
					var tld = parts.pop();
					if (!parts.length || !/^([a-z\u00a1-\uffff]{2,}|xn[a-z0-9-]{2,})$/i.test(tld)) {
						return !1;
					}
				}
				for (var part, i = 0; i < parts.length; i++) {
					part = parts[i]; if (options.allowUnderscores) {
						if (part.indexOf('__') >= 0) {
							return !1;
						}
						part = part.replace(/_/g, '');
					}
					if (!/^[a-z\u00a1-\uffff0-9-]+$/i.test(part)) {
						return !1;
					}
					if (/[\uff01-\uff5e]/.test(part)) {
						return !1;
					}
					if (part[0] === '-' || part[part.length - 1] === '-' || part.indexOf('---') >= 0) {
						return !1;
					}
				}
				return !0;
			}
			return function (str) {
				var parts = str.split('@');
				var domain = parts.pop();
				var user = parts.join('@');
				var lowerDomain = domain.toLowerCase();
				if (lowerDomain === 'gmail.com' || lowerDomain === 'googlemail.com') {
					user = user.replace(/\./g, '').toLowerCase();
				}
				if (!_isByteLength(user, 0, 64) || !_isByteLength(domain, 0, 256)) {
					return !1;
				}
				if (!_isFQDN(domain)) {
					return !1;
				}
				if (user[0] === '"') {
					user = user.slice(1, user.length - 1);
					return quotedEmailUserUtf8.test(user);
				}
				var pattern = emailUserUtf8Part;
				var userParts = user.split('.');
				for (var i = 0; i < userParts.length; i++) {
					if (!pattern.test(userParts[i])) {
						return !1;
					}
				}
				return !0;
			};
		})()(value);
	},//ƒ

	filenameEquals: function (name1, name2) {
		return name1.localeCompare(name2, undefined, { sensitivity: 'accent' }) === 0;
	},

	filenameEqualsFallback: function (name1, name2) {
		return name1.toUpperCase() === name2.toUpperCase();
	},

	toLinuxValidFileName: function (originalFileName) {
		return originalFileName.replace(/\//g, '_');
	},

	toMacValidFileName: function (originalFileName) {
		return originalFileName.replace(/:/g, '_');
	},

	toWindowsValidFileName: function (originalFileName) {
		return originalFileName.replace(/\?|\/|\||\\|\*|:|"|<|>| $|\.$/g, '_');
	},

	toOSValidFileName: function (originalFileName) {
		const osName = typeof bowser !== 'undefined' ? iurio.utils.browser.getOSName() : undefined;
		switch (osName) {
			case 'macOS':
			case 'iOS':
				return this.toMacValidFileName(originalFileName);

			case 'Linux':
			case 'Android':
			case 'Chrome OS':
				return this.toLinuxValidFileName(originalFileName);

			default:
				// Windows is the most restrictive - so choose this by default
				return this.toWindowsValidFileName(originalFileName);
		}
	},

	toCSVDateTimeString: function (date) {
		return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
	},

	base64utils: {
		base64alphabet: [
			'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M',
			'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
			'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm',
			'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
			'0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '+', '/'
		],

		base64codes: [
			255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255,
			255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255,
			255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 62, 255, 255, 255, 63,
			52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 255, 255, 255, 0, 255, 255,
			255, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,
			15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 255, 255, 255, 255, 255,
			255, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
			41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51
		],

		getBase64Code: function (charCode) {
			if (charCode >= this.base64codes.length) {
				throw new Error('String contains an invalid character');
			}
			const code = this.base64codes[charCode];
			if (code === 255) {
				throw new Error('String contains an invalid character');
			}
			return code;
		},

		bytesToBase64: function (bytes) {
			let result = '';
			const l = bytes.length;
			let i;
			for (i = 2; i < l; i += 3) {
				result += this.base64alphabet[bytes[i - 2] >> 2];
				result += this.base64alphabet[((bytes[i - 2] & 0x03) << 4) | (bytes[i - 1] >> 4)];
				result += this.base64alphabet[((bytes[i - 1] & 0x0F) << 2) | (bytes[i] >> 6)];
				result += this.base64alphabet[bytes[i] & 0x3F];
			}
			if (i === l + 1) { // 1 octet yet to write
				result += this.base64alphabet[bytes[i - 2] >> 2];
				result += this.base64alphabet[(bytes[i - 2] & 0x03) << 4];
				result += '==';
			}
			if (i === l) { // 2 octets yet to write
				result += this.base64alphabet[bytes[i - 2] >> 2];
				result += this.base64alphabet[((bytes[i - 2] & 0x03) << 4) | (bytes[i - 1] >> 4)];
				result += this.base64alphabet[(bytes[i - 1] & 0x0F) << 2];
				result += '=';
			}
			return result;
		},

		base64ToBytes: function (str) {
			const modlen = str.length % 4;
			if (modlen !== 0) {
				switch (modlen) {
					case 1:
						throw new Error('Unable to parse base64 string.');
					case 2:
						str += '==';
						break;
					case 3:
						str += '=';
						break;
				}
			}
			const index = str.indexOf('=');
			if (index !== -1 && index < str.length - 2) {
				throw new Error('Unable to parse base64 string.');
			}
			const missingOctets = str.endsWith('==') ? 2 : str.endsWith('=') ? 1 : 0;
			const n = str.length;
			const result = new Uint8Array(3 * (n / 4));
			let buffer;
			for (let i = 0, j = 0; i < n; i += 4, j += 3) {
				buffer =
					this.getBase64Code(str.charCodeAt(i)) << 18 |
					this.getBase64Code(str.charCodeAt(i + 1)) << 12 |
					this.getBase64Code(str.charCodeAt(i + 2)) << 6 |
					this.getBase64Code(str.charCodeAt(i + 3));
				result[j] = buffer >> 16;
				result[j + 1] = (buffer >> 8) & 0xFF;
				result[j + 2] = buffer & 0xFF;
			}
			return result.subarray(0, result.length - missingOctets);
		},
	},

	/**
	 * base64 to UTF-16
	 * @param {string} b64
	 * @return {string}
	 */
	atou: function (b64) {
		return new TextDecoder().decode(this.base64utils.base64ToBytes(b64));
	},

	/**
	 * UTF-8 to base64
	 * @param {string} str
	 * @return {string}
	 */
	u8toa: function (str) {
		return this.base64utils.bytesToBase64(new TextEncoder().encode(str));
	},

	/**
	 * UTF-16 to base64
	 * @param {string} str
	 * @return {string}
	 */
	utoa: function (str) {
		return this.base64utils.bytesToBase64(this.strToUTF16Bytes(str));
	},

	/**
	 * String to UTF-16 bytes
	 * @param {string} str
	 * @return {number[]}
	 */
	strToUTF16Bytes: function (str) {
		const bytes = [];
		for (let i = 0; i < str.length; ++i) {
			const code = str.charCodeAt(i);
			bytes.push(code & 0xFF, code >> 8);
		}
		return bytes;
	},

	/**
	 * base64 to base64url (url-safe)
	 * @param {string} b64
	 * @return {string}
	 */
	base64ToBase64url: function (b64) {
		return b64.replace(/=+$/, '').replace(/\+/g, '-').replace(/\//g, '_');
	},

	/**
	 * base64url to base64
	 * @param {string} b64url
	 * @return {string}
	 */
	base64urlToBase64: function (b64url) {
		let b64 = b64url.replace(/-/g, '+').replace(/_/g, '/');
		let pad = b64.length % 4;
		if (pad) {
			b64 += '='.repeat(4 - pad);
		}
		return b64;
	},

	isPopupBlocked: function (popup) {
		try {
			if (typeof popup === 'undefined' ||!popup) {
				console.log('no popup', popup);
				return true;
			} else if (popup.closed) {
				console.log('instant close');
				return true;
			}
			console.log(popup);
		} catch (err) {
			console.warn('Error reading popup', err);
			return true;
		}

		return false;
	},

	/* global iurioApp */
	showPopupBlockerInstructions: function (alertFunc) {
		let title = 'Ein Pop-Up von IURIO wurde blockiert';
		let instructions;
		switch (iurio.utils.browser.getBrowserName()) {
			case 'Firefox':
				instructions = 'Bitte klicken Sie auf Optionen in der gelben Warnleiste, und wählen Sie "Pop-Ups erlauben"';
				break;

			case 'Chrome':
				instructions = 'Bitte klicken Sie auf das Pop-Up-Symbol rechts in der Adressleiste und wählen Sie "immer erlauben"';
				break;

			case 'Safari':
				instructions = 'Bitte klicken Sie auf das Pop-Up-Symbol rechts in der Adressleiste';
				break;

			default:
				instructions = 'Bitte erlauben Sie Pop-Ups, um die vollständige Funktionalität von IURIO zu gewährleisten';
				break;
		}
		if (iurioApp && iurioApp.$i18n) {
			title = iurioApp.$i18n.t('' + title + '');
			instructions = iurioApp.$i18n.t('' + instructions + '');
		}
		if (alertFunc) {
			alertFunc(instructions, { title: title });
		} else {
			window.alert(title + '\n' + instructions);
		}
	},

	openPopup: function (url, alertFunc) {
		const popup = window.open(url, '_blank');
		if (this.isPopupBlocked(popup)) {
			this.showPopupBlockerInstructions(alertFunc);
		}
		return popup;
	},

	isIOSApp: function () {
		return !!(window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.iurioMessageHandler);
	},

	isAndroidApp: function () {
		return !!window.androidMessageHandler;
	},

	isApp: function () {
		return this.isIOSApp() || this.isAndroidApp();
	},

	isMobile: function () {
		return iurio.utils.browser.getPlatformType(true) !== 'desktop';
	},

	isMobileSafari: function () {
		return !!iurio.utils.browser.satisfies({
			mobile: { safari: '>=0' },
			tablet: { safari: '>=0' },
		});
	},

	getDeviceName: function () {
		let platform = iurio.utils.browser.getPlatformType(true);
		platform = platform.charAt(0).toUpperCase() + platform.slice(1);
		const os = iurio.utils.browser.getOSName();
		return os + ' on ' + platform;
	},

	postAppMessage: function (...args) {
		if (this.isIOSApp()) {
			return window.webkit.messageHandlers.iurioMessageHandler.postMessage(...args);
		} else if (this.isAndroidApp()) {
			return window.androidMessageHandler.postMessage(...args);
		}
		return false;
	},

	isOfficeAddin: function () {
		return window.location.hostname === 'addin.iurio.com';
	},

	getHostname: function () {
		return this.isOfficeAddin() ? iurioData.host : window.location.hostname;
	},

	getOrigin: function () {
		return this.isOfficeAddin() ? `https://${iurioData.host}` : window.location.origin;
	},

	promisifyOfficeCall: (fn, ...args) => {
		return new Promise((resolve, reject) => {
			fn(...args, (result) => {
				result.error ? reject(result.error) : resolve(result.value);
			});
		});
	},

	getSizeText: function (sizeInBytes, locale) {
		if (!locale) {
			locale = 'de';
		}
		let sizeInMB = sizeInBytes / (1024 * 1024);
		if (sizeInMB > 1000) {
			let sizeInGB = sizeInMB / 1024;
			let result = (sizeInGB.toLocaleString(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2} ) + ' GB');
			return result;
		}
		let result = (sizeInMB.toLocaleString(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2} ) + ' MB');
		return result;
	},

	formatTime: function (seconds) {
		if (seconds < 0) {
			throw 'invalid';
		}
		return [
			Math.trunc(seconds / 3600),
			Math.trunc(seconds / 60) % 60,
			seconds % 60,
		].map(time => time.toString().padStart(2, '0')).join(':');
	},

	msToHumanReadable: function(ms, displayMinute = true) {
		if (typeof ms !== 'number' || ms < 0) {
			return undefined;
		}

		// Constants for each unit in milliseconds
		const WEEK_MS = 604800000;
		const DAY_MS = 86400000;
		const HOUR_MS = 3600000;
		const MINUTE_MS = 60000;

		// First, calculate weeks (7 days per week)
		const weeks = Math.floor(ms / WEEK_MS);
		ms -= weeks * WEEK_MS;    // Subtract the milliseconds for weeks

		// Then, calculate days (24 hours per day)
		const days = Math.floor(ms / DAY_MS);
		ms -= days * DAY_MS;      // Subtract the milliseconds for days

		// Then, calculate hours (60 minutes per hour)
		const hours = Math.floor(ms / HOUR_MS);
		ms -= hours * HOUR_MS;    // Subtract the milliseconds for hours

		// Finally, calculate minutes (60 seconds per minute)
		const minutes = Math.floor(ms / MINUTE_MS);

		// Adding units to the result
		const readable = [];
		if (weeks > 0) { readable.push(`${weeks}${iurio.crypto.utils.$t('Wo')}`); }
		if (days > 0) { readable.push(`${days}${iurio.crypto.utils.$t('Tg')}`); }
		if (hours > 0) { readable.push(`${hours}${iurio.crypto.utils.$t('Std')}`); }
		if (minutes > 0 && (displayMinute || !weeks)) {
			readable.push(`${minutes}${iurio.crypto.utils.$t('Min')}`);
		}

		return readable.join(' ') || undefined;
	},

	Mutex: class {
		constructor() {
			this._queue = [];
			this._pending = false;
		}
		isLocked() {
			return this._pending;
		}
		acquire() {
			const _queue = this._queue;
			const ticket = new Promise(function (resolve) { return _queue.push(resolve); });
			if (!this._pending) {
				this._dispatchNext();
			}
			return ticket;
		}
		runExclusive(callback) {
			return this
				.acquire()
				.then(function (release) {
					let result;
					try {
						result = callback(); //eslint-disable-line callback-return
					}
					catch (e) {
						release();
						throw (e);
					}
					return Promise
						.resolve(result)
						.then(
							function (x) { return (release(), x); },
							function (e) {
								release();
								throw e;
							}
						);
				});
		}
		_dispatchNext() {
			if (this._queue.length > 0) {
				this._pending = true;
				this._queue.shift()(this._dispatchNext.bind(this));
			}
			else {
				this._pending = false;
			}
		}
	},
};

iurio.utils.init();

if (typeof module !== 'undefined') {
	module.exports = iurio.utils;
}
